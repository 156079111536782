
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "individual-client-employer-information",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  data() {
    return {
      employerDetails: {
        employerName: "",
        jobPosition: "",
        employeeId: "",
        annualSalaryRange: "",
        employerAddress: ""
        // password: "",
      }
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);

    const employerDetailsValidator = Yup.object().shape({
      employerName: Yup.string().required().label("Employer name"),
      jobPosition: Yup.string().label("Job position"),
      employeeId: Yup.string().required().label("Employee ID"),
      annualSalaryRange: Yup.string().required().label("Annual salary range"),
      employerAddress: Yup.string().required().label("Employer Address")
      // password: Yup.string()
      //   .min(8)
      //   .required()
      //   .label("Password"),
    });

    const updateEmployerInformation = (values) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        // Send employer information update request
        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.patch(variables.PROFILE_EMPLOYER_INFORMATION, values)
            .then((response) => {
              variables.toastAlert(response.data.message, "success");
            })
            .catch(function (error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Employer Information", ["Profile"]);
    });

    return {
      submitButton1,
      updateEmployerInformation,
      employerDetailsValidator
    };
  },
  methods: {
    getEmployerInformation() {
      //Fetch individual client profile employer infomation
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.PROFILE_EMPLOYER_INFORMATION)
          .then(({ data }) => {
            //Assign data to form fields
            this.employerDetails.employerName = data.data.employerName;
            this.employerDetails.jobPosition = data.data.jobPosition;
            this.employerDetails.employeeId = data.data.employeeId;
            data.data.alternativePhoneNumber;
            this.employerDetails.annualSalaryRange =
              data.data.annualSalaryRange;
            this.employerDetails.employerAddress = data.data.employerAddress;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    }
  },
  mounted() {
    // Get user employer information on page load
    this.getEmployerInformation();
  },
  created() {
    //Set page title
    document.title = "Employer Information | " + process.env.VUE_APP_TITLE;
  }
});
